<template>
  <div class="payment-hints">
    <ul class="payment-hints__list">
      <li class="payment-hints__item">
        <span>
          {{ $t('pay-hints.1') }}
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          {{ $t('pay-hints.2') }}
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          {{ $t('pay-hints.3') }}
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          {{ $t('pay-hints.4') }}
        </span>
      </li>
      <li class="payment-hints__item">
        <span>
          {{ $t('pay-hints.5') }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PaymentHints',
};
</script>

<style scoped lang="sass">
.payment-hints
  margin: 26px 0px 30px
  background: #0b1b28
  padding: 20px
  border-radius: 6px
  font-size: 15px

  @media screen and (max-width: 469px)
    padding: 15px

.payment-hints__list
  padding: 0px
  margin: 0px
  list-style: none
  line-height: 1.36
  letter-spacing: 0.3px
  counter-reset: hints

.payment-hints__item
  display: flex
  align-items: center
  counter-increment: hints
  padding: 9px 5px
  margin-bottom: 12px
  border-bottom: 1px solid #172b3f

  &::before
    content: counter(hints)
    display: block
    margin-top: -6px
    margin-right: 20px
    font-size: 20px
    color: #56d588

  &:last-child
    margin-bottom: 0px
    border: none
</style>
