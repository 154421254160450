<template>
  <div class="payform">
    <PayFormHeader
      :title="$t('support-head-title')"
      time=""
    ></PayFormHeader>
    <div class="payform-content">
      <h2 class="payform-content__title">
        {{ $t('support-title') }}:
        <span class="color-brand"><span>№</span> {{ orderId }}</span>
      </h2>
      <div class="payform-content__inner">
        <p>
          {{ $t('notice.support.mistake') }}
        </p>
        <SupportForm></SupportForm>
      </div>
    </div>
    <div class="payform-footer">
      <RouterLink :to="backLink">
        <FooterLeftButton :text="$t('back-to-the-requisites')"></FooterLeftButton>
      </RouterLink>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import PayFormHeader from '@/components/PayFormHeader.vue';
import SupportForm from '@/components/SupportForm.vue';
import FooterLeftButton from '@/components/FooterLeftButton.vue';

const route = useRoute();
const orderId = route.params.id === 'undefined' ? '' : route.params.id;
const backLink = route.params.lang
  ? `/${route.params.lang}/${route.params.id}/${route.params.secret}/`
  : `/${route.params.id}/${route.params.secret}/`;
</script>

<style scoped lang="sass">
@import '../assets/sass/btn'
@import '../assets/sass/payform-content'
</style>
