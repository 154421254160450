import { createWebHistory, createRouter } from 'vue-router';

import MainView from './views/MainView.vue';
import SupportView from './views/SupportView.vue';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: MainView,
  },
  {
    path: '/:lang(\\w+)?/:id(\\d+)/:secret',
    component: MainView,
  },
  {
    path: '/:lang(\\w+)?/support/:id(\\d+)/:secret',
    component: SupportView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   document.title = `${to.meta.title} №${to.params.id}`;
//   next();
// });

export default router;
