const useOrderCreateAtFormatter = (createdAtMilliseconds, ttl = 20) => {
  const timerEndAt = createdAtMilliseconds + ttl * 60000;
  const restMilliseconds = timerEndAt - Date.now();

  if (Math.max(restMilliseconds, 0)) {
    let min = Math.floor((restMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    let sec = Math.floor((restMilliseconds % (1000 * 60)) / 1000);

    if (min < 10) {
      min = `0${min}`;
    }

    if (sec < 10) {
      sec = `0${sec}`;
    }

    return {
      minutes: min,
      seconds: sec,
    };
  }

  return {
    minutes: '00',
    seconds: '00',
  };
};

export default useOrderCreateAtFormatter;
