<template>
  <div class="payform-status">
    <IconFail></IconFail>
    <p>{{ $t('errors.order-time-is-up') }}</p>
    <div class="payform__btn-wrapper">
      <a
        class="payform__btn"
        :href="returnUrl"
      >
        <span>{{ $t('back-to-the-site') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import IconFail from './IconFail.vue';

export default {
  name: 'OrderError',
  props: {
    returnUrl: String,
  },
  components: {
    IconFail,
  },
};
</script>
