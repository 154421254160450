const renameProperty = (target, oldName, name) => {
  const newTarget = { ...target };
  newTarget[name] = newTarget[oldName];
  delete newTarget[oldName];

  return newTarget;
};

const fetchOrder = async (
  lang,
  orderId,
  orderSecret,
  successCallback = () => {},
  errorCallback = () => {},
) => {
  let result = null;
  const formData = new FormData();

  formData.set('id', orderId);
  formData.set('secret', orderSecret);

  const request = lang.toLowerCase() === 'mxn' ? '/send_mxn.php' : '/send.php';

  if (process.env.NODE_ENV === 'production') {
    try {
      await fetch(request, { method: 'POST', body: formData })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error('Incorrect URL query parameters.');
          }

          return response.json();
        })
        .then((data) => {
          let newData = null;

          if (lang.toLowerCase() === 'mxn') {
            newData = { ...data.items[0] };
            newData = renameProperty(newData, 'account_number', 'requisites');
            newData = renameProperty(newData, 'order_id', 'orderId');
            newData = renameProperty(newData, 'created_at', 'createdAt');
            newData = renameProperty(newData, 'updated_at', 'updatedAt');
            newData = renameProperty(newData, 'amount', 'fromAmount');
            newData = renameProperty(newData, 'order_status', 'status');
            newData = renameProperty(newData, 'recipient_name', 'recipientName');
            newData.bankName = '';
            newData.ttl = 20;
            result = newData;
          } else {
            newData = { ...data };
            newData = renameProperty(newData, 'order_id', 'orderId');
            newData = renameProperty(newData, 'created_at', 'createdAt');
            newData = renameProperty(newData, 'updated_at', 'updatedAt');
            newData = renameProperty(newData, 'from_amount', 'fromAmount');
            newData = renameProperty(newData, 'bank_name', 'bankName');
            newData = renameProperty(newData, 'return_url', 'returnUrl');
            result = newData;
          }

          successCallback(result);
        })
        .catch((err) => {
          throw new Error(err);
        });
    } catch (err) {
      result = false;

      errorCallback(err);
    }
  } else {
    await new Promise((res) => {
      setTimeout(() => {
        res({
          status: 1,
          requisites: 648454848238384,
          abbreviation: 'RUB',
          orderId: 15647,
          createdAt: { date: '05.02.2025 13:16:00' },
          updatedAt: { date: '05.02.2025 13:16:00' },
          fromAmount: '1000',
          bankName: 'ALFA',
          returnUrl: 'google.com',
        });
      }, 200);
    }).then((data) => {
      result = data;

      successCallback(result);
    });
  }

  return result;
};

export default fetchOrder;
