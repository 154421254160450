<template>
  <RouterView></RouterView>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="sass">
@import './assets/sass/common'
@import './assets/sass/btn'

.payform
  max-width: 560px
  width: 100%

  @media screen and (max-width: 820px)
    max-width: 420px

  @media screen and (max-width: 469px)
    max-width: 340px

.payform-footer
  padding: 8px 20px
  margin: -2px 0px 0px
  background: #0f2031
  border-radius: 2px 2px 6px 6px

  @media screen and (max-width: 469px)
    padding: 8px 10px
</style>
