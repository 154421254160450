<template>
  <div class="payform-content">
    <h2 class="payform-content__title">
      {{ $t('order-number') }}: <span class="color-brand">№ {{ orderInfo.orderId }}</span>
    </h2>
    <OrderError
      v-if="
        orderInfo.status === 4 ||
        orderInfo.status === 5 ||
        String(orderInfo.status).toLowerCase() === 'canceled' ||
        String(orderInfo.status).toLowerCase() === 'autocanceled'
      "
      :returnUrl="orderInfo.returnUrl"
    ></OrderError>
    <OrderSuccess
      v-else-if="orderInfo.status === 3 || String(orderInfo.status).toLowerCase() === 'completed'"
      :returnUrl="orderInfo.returnUrl"
    ></OrderSuccess>
    <div
      v-else
      class="payform-content__inner"
    >
      <p>{{ $t('notice.make-transaction-on-requisites') }}</p>
      <div class="card">
        <ReqField
          class="card-field"
          :caption="
            String(orderInfo.bankName).trim()
              ? $t('input-labels.phone')
              : $t('input-labels.requisites')
          "
          :content="String(orderInfo.requisites)"
        ></ReqField>
        <ReqField
          class="card-field"
          :caption="$t('input-labels.transaction-amount')"
          :content="
            String(orderInfo.fromAmount) + ' ' + `${lang === 'mxn' ? 'MX' : orderInfo.abbreviation}`
          "
        ></ReqField>
        <template v-if="lang === 'mxn'">
          <ReqField
            class="card-field"
            caption="Concepto del Pago"
            :content="String(orderInfo.concept)"
          ></ReqField>
          <ReqField
            class="card-field"
            caption="Nombre del Receptor"
            :content="String(orderInfo.recipientName)"
          ></ReqField>
        </template>
        <ReqField
          v-if="String(orderInfo.bankName).trim()"
          class="card-field"
          :caption="$t('input-labels.bank-name')"
          :content="orderInfo.bankName"
        ></ReqField>
      </div>
      <p style="margin: 8px 0px 0px">
        {{ $t('notice.auto-check-system') }}
      </p>
      <div class="payform__btn-wrapper">
        <button
          type="button"
          class="payform__btn"
          @click.once="waitButtonMode"
        >
          <span v-if="!paymentReady">{{ $t('buttons.was-make-transaction') }}</span>
          <div
            v-else
            class="lds-facebook"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button>
        <transition name="wait-fade">
          <p
            v-if="paymentReady"
            style="margin-top: 10px; font-size: 14px"
          >
            {{ $t('notice.under-button-waiting') }}
          </p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import ReqField from './ReqField.vue';
import OrderError from './OrderError.vue';
import OrderSuccess from './OrderSuccess.vue';

export default {
  name: 'PayFormContent',
  components: {
    ReqField,
    OrderError,
    OrderSuccess,
  },
  setup() {
    const paymentReady = ref(false);
    const lang = inject('lang');

    return {
      lang,
      paymentReady,
    };
  },
  props: {
    orderInfo: Object,
  },
  methods: {
    waitButtonMode() {
      this.paymentReady = true;
    },
  },
};
</script>

<style scoped lang="sass">
@import '../assets/sass/loaders'
@import '../assets/sass/payform-content'

.payform-status
  max-width: 460px
  margin: 10px auto
  text-align: center

  p

.card
  box-sizing: border-box
  padding: 10px 14px
  margin: 12px auto 0px
  border-radius: 6px

.card-field + .card-field
  margin: 12px 0px 0px

.wait-fade-enter-active,
.wait-fade-leave-active
  transition: opacity 0.5s ease

.wait-fade-enter-from,
.wait-fade-leave-to
  opacity: 0
</style>
