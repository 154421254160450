<template>
  <form
    v-if="status === 'filling' || status === 'wait'"
    class="support-form"
    @submit.prevent="onSubmitForm"
  >
    <div class="support-form__field">
      <label
        class="support-form__check-attach"
        for="attachment-file"
      >
        <span><i>📌</i> {{ fileName }}</span>
        <input
          class="support-form__file"
          type="file"
          id="attachment-file"
          accept="image/png, image/jpeg"
          @change="onSelectFileToUpload"
        />
      </label>
      <p
        v-if="errors.file.message"
        class="support-form__error support-form__error_file"
      >
        {{ errors.file.message }}
      </p>
    </div>
    <div class="support-form__field">
      <label for="clientContact">
        <input
          id="clientContact"
          class="inp"
          type="email"
          :placeholder="$t('input.email.placeholder')"
          required
          v-model="clientContact"
        />
      </label>
    </div>
    <div class="support-form__field">
      <label for="clientComment">
        <textarea
          id="clientComment"
          :placeholder="$t('input.comment.placeholder')"
          class="inp inp_textarea"
          required
          minlength="20"
          v-model="clientComment"
        ></textarea>
      </label>
      <div
        v-if="clientComment.length && clientComment.length < 20"
        class="support-form__error"
      >
        {{ $t('errors.min-length-comment-input') }} {{ clientComment.length }}
      </div>
    </div>
    <div class="payform__btn-wrapper">
      <button
        type="submit"
        class="payform__btn"
      >
        <span v-if="status !== 'wait'">{{ $t('buttons.send') }} <i></i></span>
        <div
          v-else
          class="lds-facebook"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
      <transition name="wait-fade">
        <p
          v-if="status === 'wait'"
          style="margin: 10px 0px 0px; font-size: 14px"
        >
          {{ $t('notice.under-button-waiting') }}
        </p>
      </transition>
    </div>
  </form>
  <div v-if="status === 'fail'">
    <IconFail style="margin: 12px 0px 0px"></IconFail>
    <p>{{ $('errors.was-error-try-refresh-page') }}</p>
    <div class="payform__btn-wrapper">
      <RouterLink
        class="payform__btn"
        :to="backLink"
      >
        <span>{{ $t('step-back') }}</span>
      </RouterLink>
    </div>
  </div>
  <div v-if="status === 'success'">
    <IconSuccess style="margin: 12px 0px 0px"></IconSuccess>
    <p>{{ $t('sucesss.support-message-was-send') }}</p>
    <div class="payform__btn-wrapper">
      <RouterLink
        class="payform__btn"
        :to="backLink"
      >
        <span>{{ $t('step-back') }}</span>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import sendSupportForm from '@/api/sendSupportForm';
import IconFail from './IconFail.vue';
import IconSuccess from './IconSuccess.vue';

export default {
  name: 'SupportForm',
  setup() {
    const route = useRoute();
    const status = ref('filling');
    const clientContact = ref('');
    const clientComment = ref('');
    const fileName = ref();
    let uploadedFile = null;

    const errors = ref({
      file: {
        message: '',
      },
    });

    const getUIDfromURL = () => {
      const id = route.params.id ? route.params.id : 'no';

      return `${id}`;
    };

    const onSubmitForm = async () => {
      if (!uploadedFile) {
        errors.value.file.message = this.$t('errors.file-not-select');
        return;
      }

      errors.value.file.message = '';
      status.value = 'wait';

      const result = await sendSupportForm(
        clientContact.value,
        clientComment.value,
        uploadedFile,
        getUIDfromURL(),
      );

      if (result.ok) {
        status.value = 'success';
      } else {
        status.value = 'fail';
      }
    };

    const onSelectFileToUpload = (evt) => {
      const { currentTarget } = evt;
      const file = evt.currentTarget.files[0];

      const { name, size } = file;

      uploadedFile = file;

      // 5000000 bytes = 5 MB
      if (size > 5000000) {
        currentTarget.value = null;
        uploadedFile = null;
        errors.value.file.message = this.$t('errors.file-size-max');
        fileName.value = this.$t('buttons.file-attach');

        return;
      }

      errors.value.file.message = '';
      fileName.value = name;
    };

    return {
      clientContact,
      clientComment,
      fileName,
      errors,
      status,
      onSubmitForm,
      onSelectFileToUpload,
    };
  },
  computed: {
    backLink() {
      const route = useRoute();

      if (route.params.lang) {
        return `/${route.params.lang}/${route.params.id}/${route.params.secret}/`;
      }

      return `/${route.params.id}/${route.params.secret}/`;
    },
  },
  mounted() {
    this.fileName = this.$t('buttons.file-attach');
  },
  components: {
    IconFail,
    IconSuccess,
  },
};
</script>

<style scoped lang="sass">
@import '../assets/sass/loaders'

p
  margin-bottom: 0px

.support-form
  margin: 16px 0px 0px

.support-form__check-attach
  display: block
  padding: 30px 10px
  border: 1px solid #ffffff14
  background-color: rgba(255, 255, 255, 0.03)
  border-radius: 6px
  cursor: pointer
  transition: background-color 300ms linear, color 300ms linear, border-color 300ms linear

  &:focus,
  &:hover
    background-color: rgba(255, 255, 255, 0.08)
    color: white
    border-color: $brand-color

.support-form__file
  display: none

.support-form__error
  margin: 7px 0px 0px
  text-align: left
  color: #e5583c
  letter-spacing: 0.5px
  font-size: 13px

.support-form__clientComment-length
  text-align: left
  font-size: 13px
  margin: 8px 6px 0px

.support-form__error_file
  text-align: center

.support-form__field + .support-form__field
  margin: 20px 0px 0px

.inp
  display: block
  width: 100%
  font-size: 15px
  padding: 15px
  box-sizing: border-box
  border-radius: 6px
  outline: none
  border: 1px solid #ffffff14
  background: #061523
  color: $text-color-second
  letter-spacing: 0.5px
  transition: border-color 300ms linear
  font-family: 'Golos Text', Arial, Helvetica, sans-serif

  &::placeholder
    font-size: 15px

  &:focus
    border-color: $brand-color

  &:autofill,
  &:auto-fill
    -webkit-box-shadow: 0px 0px 0px 40px #061523 inset
    -webkit-text-fill-color: $text-color-second
    font-size: 15px

.inp_textarea
  resize: vertical
  max-height: 260px
  min-height: 90px
</style>
