import { createI18n } from 'vue-i18n';
import { createApp } from 'vue';
import router from './router';
import App from './App.vue';

// Languages
// # rus - Russian
// # mxn - Spanish
const languages = ['rus', 'mxn'];
const defineTranslates = (...rest) => [...rest].map((w, i) => [languages[i], w]);

const texts = [
  ['page-main-view.title', defineTranslates('Оплата платежа', 'Pago del pago')],
  [
    'not-found-order',
    defineTranslates(
      'Не удалось найти заявку по указанным параметрам. Проверьте пожалуйста введенные данные.',
      `No se ha podido encontrar la aplicación de acuerdo con los parámetros especificados.
        Por favor, compruebe los datos introducidos.`,
    ),
  ],
  [
    'no-permission-for-partner',
    defineTranslates(
      'Форма оплаты не доступна для текущего партнера.',
      'El formulario de pago no está disponible para el socio actual.',
    ),
  ],
  ['order-title.instruction', defineTranslates('Инструкция оплаты', 'Instrucciones de pago')],
  ['order-title.time-is-up', defineTranslates('Время платежа вышло', 'Se acabó el tiempo de pago')],
  ['order-title.complete', defineTranslates('Перевод выполнен', 'Solicitud cumplida')],
  ['support-head-title', defineTranslates('Поддержка клиента', 'Atención al cliente')],
  ['support-title', defineTranslates('Помощь с платежом', 'Ayudas de pago')],
  ['order-number', defineTranslates('Номер платежа', 'Número')],
  ['input-labels.requisites', defineTranslates('Реквизиты для оплаты', 'Datos de pago')],
  ['input-labels.phone', defineTranslates('Номер телефона', 'Número de teléfono')],
  ['input-labels.bank-name', defineTranslates('Название банка:', 'Nombre del banco')],
  [
    'input-labels.transaction-amount',
    defineTranslates('Точная сумма перевода', 'El importe exacto de la transferencia'),
  ],
  ['notice.was-copy', defineTranslates('Скопировано!', '¡Copiado!')],
  [
    'notice.make-transaction-on-requisites',
    defineTranslates(
      'Сделайте перевод по указанным реквизитам:',
      'Envíe los fondos a los datos facilitados:',
    ),
  ],
  [
    'notice.auto-check-system',
    defineTranslates(
      'Наша система в автоматическом режиме проверяет поступления на указанные реквизиты.',
      'El sistema comprueba automáticamente la recepción de fondos en la cuenta especificada.',
    ),
  ],
  [
    'notice.support.mistake',
    defineTranslates(
      `Если вы допустили ошибку при переводе, отправьте нам чек оплаты и оставьте контакт для связи.
        В случае, если вы не совершили перевод, а время платежа вышло — просто создайте новую заявку.`,
      `Si se ha equivocado al hacer una transferencia, envíenos un justificante de pago y deje un
        contacto para la comunicación. En caso de que no hayas realizado ninguna transferencia
        y haya pasado el plazo de pago, simplemente crea una nueva solicitud.`,
    ),
  ],
  ['notice.under-button-waiting', defineTranslates('Ожидайте...', 'Espera...')],
  ['buttons.was-make-transaction', defineTranslates('Я перевел', 'He traducido')],
  ['buttons.client-support', defineTranslates('Поддержка клиента', 'Atención al cliente')],
  ['buttons.send', defineTranslates('Отправить', 'Enviar')],
  ['buttons.file-attach', defineTranslates('Прекрепить файл', 'Adjuntar captura de pantalla')],
  ['input.email.placeholder', defineTranslates('Введите ваш Email', 'Introduzca su Email')],
  [
    'input.comment.placeholder',
    defineTranslates('Введите ваш комментарий', 'Escriba su comentario'),
  ],
  [
    'pay-hints.1',
    defineTranslates(
      `Вы должны перевести ровно указанную сумму (не больше и не меньше),
        иначе ваш платеж зачислен не будет! При переводе не точной суммы
        вы можете оплатить чужой заказ и потерять средства.`,
      `Debe transferir exactamente el importe especificado (ni más ni menos), de lo
        contrario no se abonará su pago. Si transfiere una cantidad inexacta,
        puede pagar el pedido de otra persona y perder dinero.`,
    ),
  ],
  [
    'pay-hints.2',
    defineTranslates(
      `Делайте перевод одним платежом, если вы разобьете
        платеж на несколько, ваш платеж зачислен не будет!`,
      `Realice la transferencia en un solo pago, si
        divide el pago en varios, no se le abonará.`,
    ),
  ],
  [
    'pay-hints.3',
    defineTranslates(
      `Перевод нужно осуществить в течении 20 мин. после создания заказа.
        Если вам не хватает времени, отмените заявку и создайте новую!`,
      `La transferencia debe realizarse en los 20 minutos siguientes a
        la creación de la orden. Si tienes poco tiempo, cancela la orden y crea una nueva.`,
    ),
  ],
  [
    'pay-hints.4',
    defineTranslates(
      `Если у вас возникли какие-либо проблемы с оплатой, обратитесь
        в поддержку. Проблемы с оплатой рассматриваются в течении 48 часов.`,
      `Si tiene algún problema con el pago, póngase en contacto con el
        servicio de asistencia. Los problemas de pago se resuelven en 48 horas.`,
    ),
  ],
  [
    'pay-hints.5',
    defineTranslates(
      `После оплаты, нажмите кнопку "Я перевел" и подождите
        5-10 минут, наша система проверит ваш платеж.`,
      `Después del pago, haga clic en «He traducido» y
        espere 5-10 minutos, nuestro sistema verificará su pago.`,
    ),
  ],
  ['back-to-the-site', defineTranslates('Вернуться на сайт', 'Volver al sitio web')],
  ['back-to-the-requisites', defineTranslates('Вернуться к реквизитам', 'Volver al pago')],
  ['step-back', defineTranslates('Вернуться назад', 'Volver atrás')],
  [
    'sucesss.payment',
    defineTranslates('Оплата прошла успешно.', 'El pago se ha realizado correctamente.'),
  ],
  [
    'sucesss.support-message-was-send',
    defineTranslates(
      'Мы получили ваше обращение, постараемся помочь как можно скорее.',
      'Hemos recibido su llamamiento, intentaremos ayudarle lo antes posible.',
    ),
  ],
  [
    'errors.order-time-is-up',
    defineTranslates(
      'Время действия заявки вышло. Если вы не успели выполнить перевод, создайте новую заявку.',
      'La solicitud ha caducado. Si no ha completado la transferencia a tiempo, cree una nueva solicitud.',
    ),
  ],
  [
    'errors.min-length-comment-input',
    defineTranslates(
      'Минимальная длина сообщения: 20 символов. Текущая длина:',
      'Longitud mínima del mensaje 20 caracteres. Longitud actual',
    ),
  ],
  [
    'errors.was-error-try-refresh-page',
    defineTranslates(
      'Произошла ошибка. Попробуйте обновить страницу и повторить попытку.',
      'Se ha producido un error. Intente actualizar la página y vuelva a intentarlo.',
    ),
  ],
  [
    'errors.file-size-max',
    defineTranslates(
      'Размер файла не может превышать 5 мегабайт.',
      'El tamaño del archivo no puede superar los 5 megabytes.',
    ),
  ],
  [
    'errors.file-not-select',
    defineTranslates(
      'Необходимо выбрать файл подтверждающий перевод. Обычно это скриншот чека.',
      'Debe seleccionar un archivo que confirme la transferencia. Normalmente se trata de una captura de pantalla de un cheque.',
    ),
  ],
];

const translations = {};

const makeDeepNestedObject = (route, obj, message) => {
  let last = null;

  (function repeat(path, targetObject = null, text = '') {
    const cloneTargetObject = targetObject;
    const restPath = path.slice(1);

    if (!restPath.length) {
      cloneTargetObject[path[0]] = text;
      last = cloneTargetObject[path[0]];
      return;
    }

    if (!cloneTargetObject[path[0]]) cloneTargetObject[path[0]] = {};
    repeat(restPath, cloneTargetObject[path[0]], text);
  })(route, obj, message);

  return last;
};

languages.forEach((lang, langIndex) => {
  texts.forEach((text) => {
    if (!translations[lang]) translations[lang] = {};

    const pathParts = text[0].split('.');
    const [langKey, message] = text[1][langIndex];

    if (pathParts.length >= 2) {
      makeDeepNestedObject(pathParts, translations[langKey], message);
    } else {
      translations[langKey][text[0]] = message;
    }
  });
});

const pathParams = window.location.pathname.slice(1).split('/');

let language = languages[0].toLowerCase();

if (pathParams.length >= 3) {
  language = pathParams[0].toLowerCase();
}

const i18n = createI18n({
  messages: {
    ...translations,
  },
  locale: language,
  fallbackLocale: 'rus',
});

createApp(App).use(router).use(i18n).provide('lang', language).mount('#app');
