<template>
  <div
    v-if="isFetched"
    class="payform"
  >
    <template v-if="(isMexican && orderInfo) || (orderInfo && orderInfo.returnUrl)">
      <PayFormHeader
        :title="headerTitle"
        :time="restTime"
      ></PayFormHeader>
      <PayFormContent :orderInfo="orderInfo"></PayFormContent>
      <div class="payform-footer">
        <RouterLink :to="supportLink">
          <FooterLeftButton
            v-if="orderInfo.status !== 3"
            :text="$t('buttons.client-support')"
          ></FooterLeftButton>
        </RouterLink>
      </div>
      <PaymentHints v-if="orderInfo.status === 1 || orderInfo.status === 'created'"></PaymentHints>
    </template>
    <p
      v-else-if="haveRequestError"
      align="center"
    >
      😵 {{ $t('not-found-order') }}
    </p>
    <p
      v-else
      align="center"
    >
      👾 {{ $t('no-permission-for-partner') }}
    </p>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import PayFormHeader from '@/components/PayFormHeader.vue';
import PayFormContent from '@/components/PayFormContent.vue';
import PaymentHints from '@/components/PaymentHints.vue';
import FooterLeftButton from '@/components/FooterLeftButton.vue';
import fetchOrder from '@/api/fetchOrder';
import useOrderCreateAtFormatter from '@/composables/useOrderCreateAtFormatter';

export default {
  name: 'MainView',
  setup() {
    const orderInfo = ref({});
    const restTime = ref('--:--');
    const isFetched = ref(false);
    const haveRequestError = ref(false);
    const orderTimerId = null;
    const refetchTimerId = null;
    const isMexican = ref(false);
    const route = useRoute();
    const selectedLang = inject('lang');

    isMexican.value = !!selectedLang;

    return {
      orderInfo,
      restTime,
      refetchTimerId,
      orderTimerId,
      isFetched,
      haveRequestError,
      isMexican,
      selectedLang,
      route,
    };
  },
  components: {
    PayFormHeader,
    PayFormContent,
    PaymentHints,
    FooterLeftButton,
  },
  computed: {
    headerTitle() {
      let result = '';

      switch (this.orderInfo.status) {
        case 3:
          result = this.$t('order-title.complete');
          break;
        case 4:
        case 5:
          result = this.$t('order-title.time-is-up');
          break;
        default:
          result = this.$t('order-title.instruction');
      }

      return result;
    },
    supportLink() {
      if (this.route.params.lang) {
        return `/${this.route.params.lang}/support/${this.route.params.id}/${this.route.params.secret}/`;
      }

      return `/support/${this.route.params.id}/${this.route.params.secret}/`;
    },
  },
  watch: {
    orderInfo() {
      clearTimeout(this.orderTimerId);

      const self = this;

      if (this.orderInfo) {
        this.orderTimerId = setTimeout(function repeat() {
          const createdAtMilliseconds = Number(
            new Date(self.orderInfo.createdAt.date.split('.')[0]),
          );
          const time = useOrderCreateAtFormatter(createdAtMilliseconds, self.orderInfo.ttl);
          self.restTime = `${time.minutes}:${time.seconds}`;

          self.orderTimerId = setTimeout(repeat, 1000);
        }, 0);
      }
    },
  },
  methods: {
    getOrderInfo(lang, orderId, orderSecret) {
      new Promise((res, rej) => {
        fetchOrder(lang, orderId, orderSecret, res, rej);
      }).then((data) => {
        this.orderInfo = data;

        useTitle(`${this.$t('page-main-view.title')} ${this.orderInfo.orderId}`);

        if (!this.orderInfo) {
          this.haveRequestError = true;
        }

        this.isFetched = true;
      });
    },
  },
  unmounted() {
    clearInterval(this.refetchTimerId);
    clearTimeout(this.orderTimerId);
  },
  mounted() {
    this.getOrderInfo(this.selectedLang, this.route.params.id, this.route.params.secret);

    this.refetchTimerId = setInterval(() => {
      this.getOrderInfo(this.selectedLang, this.route.params.id, this.route.params.secret);
    }, 8000);

    useTitle(this.$t('page-main-view.title'));
  },
};
</script>
