<template>
  <div class="payform-status">
    <IconSuccess></IconSuccess>
    <p>{{ $t('sucesss.payment') }}</p>
    <div class="payform__btn-wrapper">
      <a
        class="payform__btn"
        :href="returnUrl"
      >
        <span>{{ $t('back-to-the-site') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import IconSuccess from './IconSuccess.vue';

export default {
  name: 'OrderSuccess',
  props: {
    returnUrl: String,
  },
  components: {
    IconSuccess,
  },
};
</script>
