<template>
  <button type="button">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'FooterLeftButton',
  props: {
    text: String,
  },
};
</script>

<style scoped lang="sass">
@import '../assets/sass/mixin-reset-btn'

button
  @include reset-btn

  font-size: 14px
  color: $text-color-second
  cursor: pointer
  transition: color 300ms linear

  @media screen and (min-width: 767px)
    &:focus,
    &:hover
      color: #69e7a0
</style>
